import React from 'react'
import '../styles/landingpage.scss'

function modal ({ closeModal }) {

  return (
    <>
    <div className="contact-modal-bg">

      <div className="contact-container">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              closeModal(false);
            }}
          >
            X
          </button>
        </div>
        <div className="title">
          <h1>Post Scientist Developers Contact</h1>
        </div>
        <div className="spacer-medium"></div>
{/* 
        <form className="contact-form">
        <label class="name">Name:</label>
        <input type="text" id="name" name="name" required />

        <label Class="email">Email ID:</label>
        <input type="email" id="email" name="email" required />

        <label Class="phone">Phone Number:</label>
        <input type="tel" id="phone" name="phone" required />

        <label Class="notes">Additional Notes:</label>
        <textarea id="notes" name="notes" rows="4" column="2" /> */}

          <p>We will contact you shortly. You can also mail us on sales@postscientist.com </p>

        {/* <div className="spacer-small"></div>
        <button type="submit">Submit</button> */}
        {/* </form> */}
      </div>
    </div> 
     
    </>
  )
}

export default modal
