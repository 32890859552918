import React, {useState} from 'react'
import arrow from '../assets/up_arrow.svg'
import Modal from "../components/modal"

const Navbar = () => {
  const [openModal, setOpenModel] = useState(false);

  return (
    <>
        <div className="page">
            <div className='navbar'>
                <div className="container">
                  <a href="#Top" className='top_arrow'>
                    <img src={arrow} alt="arrow" />
                  </a>
                      <aside role='navigation' className='nav-menu'>
                          <a href="#Work" className='nav-menu-link'>Work</a>
                          <a href="#Process" className='nav-menu-link'>Process</a>
                          <button onClick={() => {setOpenModel(true);}} className='nav-menu-link'>Contact</button>
                      </aside>
                  <a href="#Pricing" className='Pricing'>Pricing</a>
                </div>
                <div className="m-container">

                </div>
            </div>
        </div>
        {openModal && <Modal closeModal={setOpenModel} />}
    </>
  )
}

export default Navbar
