import React from 'react'   //{UseState}
import '../styles/landingpage.scss'
import bg from '../assets/logo_bg.svg'
import tick from '../assets/tick.svg'
import Chart from '../assets/Chart.svg'
import Infinity from '../assets/Infinity.svg'
import quality from '../assets/quality.svg'
import cancel from '../assets/Cancel.svg'
import feedback from '../assets/Feedback.svg'
import money from '../assets/money.svg'
import Product from '../assets/product.png'
// import Branding from '../assets/Branding.png'
import UI from '../assets/ui.jpg'
// import Modal from './modal.jsx'

const landingpage = () => {

  const imgstyle = {
    Width: '50px',
    Height: '50px'
  };

  return (
    <div className="main">
        <div className="header">
            <div className='container-full'>
                <div className='container_logo'>
                      <a className='bg_logo'>
                        <img src={bg} alt="" className='size_bg' />
                      </a>
                </div>
                <div className='container_content'>
                      <h1 className='text-color'>
                        From hosting to design you needs,
                        </h1>
                      <span className='expand'>one subscription</span>

                      <p className='para'>Adaptability meets innovation in our fully flexible web solution.
                        Pause or cancel anytime.
                      </p>
                      <div className="container_boxes">
                        <div className="container_tick">
                             <img src={tick} alt="" />
                             <div className="text_size">
                              Personalized solution
                             </div>
                        </div>
                        <div className="container_tick">
                            <img src={tick} alt="" />
                                <div className="text_size">
                                  Prototype in 1 week
                                </div>
                        </div>
                        <div className="container_tick">
                              <img src={tick} alt="" />
                                <div className="text_size">
                                  Cancel anytime
                                </div>
                        </div>
                      </div>
                </div>
            </div>
        </div>
        <section className="work">
           <div className="padding_none">
               <div className="container_large">
                    <div className="padding-section-large">
                        <div className="content_section_arrow">
                           <div className="content_above_arrow">
                               <div className="container_large">
                                  <div className="container_medium">
                                    <div className="label">Product Subscription</div>
                                    <div className="spacer-small"/>
                                    <h2 className='text-color'>
                                      Full Fleged Web solution
                                    </h2>
                                  </div>
                               </div>
                               <div className="container_arrow">
                                    <div className="container_floating_h2">
                                       <div className="spacer-huge"></div>
                                       <h2 className="text-color">
                                        No messy contracts or paperwork
                                       </h2>
                                    </div>
                                    <div className="container_floating_h2">
                                    <div className="spacer-huge"></div>
                                      <h2 className="text-color">
                                      Experience top tier web services at your beck and call
                                       </h2>
                                      </div>
                               </div>
                           </div>
                        </div>
                    </div>
               </div>
           </div>
        </section>
        <section className="what_we_do">
          <div className="padding_global">
              <div className="container_large">
                   <div id="Work" className="padding_section_large">
                       <div className="conatiner_label">
                           <div className="label">
                              What we do
                           </div>
                       </div>
                       <div className="spacer_small"></div>
                       <div className="text-color">
                        From prototype to launch & anywhere in between
                       </div>
                       <div className="spacer-medium">

                       </div>
                       <div className="subheading_text">
                        We cover two main solutions of digital solutions to help you scale 
                        & optimized your organization in more organized manner.
                       </div>
                       <div className="spacer-medium">

                       </div>
                       <div className="container_work-categories">
                            <div className="content_work-categories">
                              <div className="text-color-wc">
                                Landing pages
                              </div>
                            </div>
                            <div className="content_work-categories">
                            <div className="text-color-wc">
                                Online applications
                              </div>
                            </div>
                            <div className="content_work-categories">
                            <div className="text-color-wc">
                                Web-based solutions
                              </div>
                            </div>
                            <div className="content_work-categories">
                            <div className="text-color-wc">
                                Cloud applications
                              </div>
                            </div>
                            <div className="content_work-categories">
                            <div className="text-color-wc">
                                Web services
                              </div>
                            </div>
                            <div className="content_work-categories">
                            <div className="text-color-wc">
                                Online platforms
                              </div>
                            </div>
                            <div className="content_work-categories">
                            <div className="text-color-wc">
                                Cloud-based solutions
                              </div>
                            </div>
                            <div className="content_work-categories">
                            <div className="text-color-wc">
                                Networked applications
                              </div>
                            </div>
                            <div className="content_work-categories">
                            <div className="text-color-wc">
                                More
                              </div>
                            </div>
                       </div>
                       <div className="spacer-medium"></div>
                       <div className="conatiner_button">

                       </div>
                       <div className="conatiner_what-we-do-categories">
                             <div className="we_do_box">
                                   <img src={Product} alt="" style={imgstyle} />
                                   <div className="spacer-medium"></div>
                                   <h3 className="text-color-we-do">Product Developement</h3>
                                   <p className='text-color-size'> We provide a feasible solution to increase your productivity 
                                   & efficiency of your company among the employees. 
                                   </p>
                             </div>
                             <div className="we_do_another-box">

                                   <h3 className="text-color-we-do">Branding & Design (UI/UX)</h3>
                                   <p className='text-color-size'> Whatever age group you want to target we provide you a feasible sulution.
                                   According to the requirements of your product & idea you have.
                                   </p>
                                   <div className="spacer-small"></div>
                                   <img src={UI} alt="" style={imgstyle}/>
                             </div>
                       </div>
                   </div>
              </div>
          </div>
        </section>
        <section className="benefits">
            <div className="padding-global">
                 <div className="container-large">
                     <div className="padding-section-large">
                         <div className="container-label">
                            <div className="label">Great Benefits</div>
                         </div>
                         <div className="spacer-small"></div>
                         <h2 className='text-color'>Subscription benefits</h2>
                         <div className="spacer-medium"></div>
                         <div className="subheading_text">
                         You will receive priority customer support. It ensures quicker response times and better assistance when needed.
                          </div>
                          <div className="spacer-huge">

                          </div>
                          <div className="container_benefit-boxes">
                            <div className="box-benefits">
                              <div className="mouse-over">
                                <img src={quality} alt="" />
                              </div>
                              <div className="spacer-small"></div>
                              <h3 className="text-color">
                                Top quality
                                </h3>
                              <div className="spacer-small"></div>
                              <div className="text-color-size">
                              Our commitment is to deliver top-notch quality services.
                              </div>
                            </div>
                            <div className="box-benefits">
                            <div className="mouse-over">
                                <img src={Infinity} alt="" />
                              </div>
                              <div className="spacer-small"></div>
                              <h3 className="text-color-head">
                                Unlimited requests
                                </h3>
                              <div className="spacer-small"></div>
                              <div className="text-color-size">
                              It ensures they're not paying for more than they require.
                              </div>
                            </div>
                            <div className="box-benefits">
                            <div className="mouse-over">
                                <img src={feedback} alt="" style={imgstyle} />
                              </div>
                              <div className="spacer-small"></div>
                              <h3 className="text-color-head">
                                Feedback constantly
                                </h3>
                              <div className="spacer-small"></div>
                              <div className="text-color-size">
                              you will receive regular updates, new features, and 
                              improvements as part of their subscription. This ensures 
                              you are always using the latest version of the product 
                              without extra charges.
                              </div>
                            </div>
                            <div className="box-benefits">
                            <div className="mouse-over">
                                <img src={Chart} alt="" />
                              </div>
                              <div className="spacer-small"></div>
                              <h3 className="text-color-head">
                                Fixed Monthly rates
                                </h3>
                              <div className="spacer-small"></div>
                              <div className="text-color-size">
                              Grant access to the full suite of features 
                              or services offered by us. It will provide 
                              better value compared to one-time purchases, 
                              where additional features might require 
                              separate purchases.
                              </div>
                            </div>
                            <div className="box-benefits">
                            <div className="mouse-over">
                                <img src={cancel} alt="" />
                              </div>
                              <div className="spacer-small"></div>
                              <h3 className="text-color-head">
                                No meetings
                                </h3>
                              <div className="spacer-small"></div>
                              <div className="text-color-size">
                              you will receive regular updates, new features, and improvements 
                              as part of their subscription. This ensures you are always using 
                              the latest version of the product without extra charges.
                              </div>
                            </div>
                            <div className="box-benefits">
                            <div className="mouse-over">
                                <img src={money} alt="" style={imgstyle} />
                              </div>
                              <div className="spacer-small"></div>
                              <h3 className="text-color-head">
                                Maintaince charges
                                </h3>
                              <div className="spacer-small"></div>
                              <div className="text-color-size">
                              It will be very less as we need only maintaince charges.
                              </div>
                            </div>
                          </div>
                     </div>
                 </div>
            </div>
        </section>
        <section className="how_it_works">
          <div className="padding-global">
              <div className="container-large">
                  <div id="Process"className="padding-section-large">
                      <div className="container-label">
                          <div className="label">
                            How it works
                          </div>
                      </div>
                      <div className="spacer-small"></div>
                      <h2 className="text-color"> Working with us is as simple as 1,2,3 go</h2>
                      <div className="spacer-medium"></div>
                      <div className="subheading_text">
                        We provide you personalized solution & 
                        also tracks the progess our our platform 
                        exclusively for our client
                        </div>
                      <div className="spacer-huge"></div>
                      <div className="container_how-it-works">
                          <div className="container_how-it-works-text">
                            <div className="label-mini">
                              One
                            </div>
                            <div className="spacer-tiny"></div>
                            <h3 className="text-align">
                              Make a call
                            </h3>
                            <div className="spacer-xsmall"/>
                            <div className="text-size-medium">
                              A product development guy will be there for you. 
                              To listen carefully & understand the requirements 
                              of your company
                            </div>
                          </div>
                          <div className="container_how-it-works-text">
                            <div className="label-mini">
                              Two
                            </div>
                            <div className="spacer-tiny"></div>
                            <h3 className="text-align">
                              Prototype
                            </h3>
                            <div className="spacer-xsmall"/>
                            <div className="text-size-medium">
                               Gives you a rough design & features you want. Take feedback accordingly
                            </div>
                          </div>
                          <div className="container_how-it-works-text">
                            <div className="label-mini">
                              Three
                            </div>
                            <div className="spacer-tiny"></div>
                            <h3 className="text-align">
                              Estimated time 
                            </h3>
                            <div className="spacer-xsmall"/>
                            <div className="text-size-medium">
                            To complete the project where we constantly providing you 
                            progress report on our platform.
                            </div>
                          </div>
                          <div className="container_how-it-works-text">
                            <div className="label-mini">
                              Four
                            </div>
                            <div className="spacer-tiny"></div>
                            <h3 className="text-align">
                              UI/UX Design
                            </h3>
                            <div className="spacer-xsmall"/>
                            <div className="text-size-medium">
                              To make any changes in the frontend 
                            </div>
                          </div>
                          <div className="container_how-it-works-text">
                            <div className="label-mini">
                              Five
                            </div>
                            <div className="spacer-tiny"></div>
                            <h3 className="text-align">
                              Customer Satisfaction is our satisfaction
                            </h3>
                            <div className="spacer-xsmall"/>
                            <div className="text-size-medium">
                              Unlimited revisions as it totally depends on your subscription charges
                            </div>
                          </div>
                          <div className="container_how-it-works-text">
                            <div className="label-mini">
                              Six
                            </div>
                            <div className="spacer-tiny"></div>
                            <h3 className="text-align">
                              Good to go 
                            </h3>
                            <div className="spacer-xsmall"/>
                            <div className="text-size-medium">
                              Wanted for maintaince there will be very minimal charges
                            </div>
                          </div>
                          <div className="box"></div>
                          <div className="box"></div>
                          <div className="box"></div>
                          <div className="box"></div>
                          <div className="box"></div>
                          <div className="box"></div>
                      </div>
                  </div>
              </div>
          </div>
        </section>
        <section className="work_slider">
              
        </section>
        <section className="pricing">
            <div className="padding-global">
                <div className="container-large">
                    <div id="Pricing"className="padding-section-large">
                        <div className="container-label">
                          <div className="label">Pricing</div>
                        </div>
                        <div className="spacer-small"/>
                        <h2 className="text-color">
                          One simple price
                          all kinds of developement
                        </h2>
                        <div className="spacer-huge"></div>
                        <div className="w-layout">
                            {/* <div className="w-layout-cell">
                               <div className="contact_pricing">
                                 <h3 className="text-color-price">
                                  Product Subscription
                                 </h3>
                                 <div className="spacer-large"></div>
                                 <div className="text-price">
                                  $1000/m
                                 </div>
                               </div>
                               <div className="spacer-large"></div>
                               <a href="#" className='button'>Get started</a>
                            </div> */}
                            <div className="w-layout-cell">
                            <div className="contact_pricing">
                                 <h3 className="text-color-price">
                                  Contact Us
                                 </h3>
                                 <div className="spacer-large"></div>
                                 <p className='text-only'>
                                  Where we understand your needs
                                 </p>
                               </div>
                               <div className="spacer-large"></div>
                               <button className='button'>Contact Us</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="FAQS"> 
           <div className="padding-none">
              <div className="container-large">
                  <div className="padding-section-large">
                      <div className="box-faq">
                          <div className="container_faq-header">
                             <h2 className="text-color">Any <br/> questions?</h2>
                          </div>
                          <div className="container_faq-right">
                             <div className="faq-wrap">
                                <div className="faq-wrap-2_questions">
                                    <div className="question">
                                    What sets your services/products apart from competitors?
                                    </div>
                                    <div className="plus-wrap">
                                      <div className="plus-horizontal"></div>
                                      <div className="vertical">
                                        {/* Onclick functionality to change the sizes & css properties */}
                                      </div>
                                    </div>
                                </div>
                                <div className="faq-wrap-2_answers">
                                    <p className='answer'>
                                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                                      Quod, quisquam ullam? Magni voluptate voluptatum error 
                                      ex expedita, omnis repudiandae ipsa pariatur ab, magnam, 
                                      quam provident eum iusto enim doloribus molestias earum 
                                      dolore ullam odit blanditiis dignissimos corrupti! Quod 
                                      alias obcaecati inventore mollitia voluptatum. Mollitia 
                                      necessitatibus atque rem provident accusantium ipsum.
                                    </p>
                                </div>
                             </div>
                             <div className="faq-wrap">
                             <div className="faq-wrap-2_questions">
                                    <div className="question">
                                    How do I contact customer support if I have an issue?
                                    </div>
                                    <div className="plus-wrap">
                                      <div className="plus-horizontal"></div>
                                      <div className="vertical">
                                        {/* Onclick functionality to change the sizes & css properties */}
                                      </div>
                                    </div>
                                </div>
                                <div className="faq-wrap-2_answers">
                                    <p className='answer'>
                                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                                      Quod, quisquam ullam? Magni voluptate voluptatum error 
                                      ex expedita, omnis repudiandae ipsa pariatur ab, magnam, 
                                      quam provident eum iusto enim doloribus molestias earum 
                                      dolore ullam odit blanditiis dignissimos corrupti! Quod 
                                      alias obcaecati inventore mollitia voluptatum. Mollitia 
                                      necessitatibus atque rem provident accusantium ipsum.
                                    </p>
                                </div>
                             </div>
                             <div className="faq-wrap">
                             <div className="faq-wrap-2_questions">
                                    <div className="question">
                                    Do you offer a trial or demo for your services/products?
                                    </div>
                                    <div className="plus-wrap">
                                      <div className="plus-horizontal"></div>
                                      <div className="vertical">
                                        {/* Onclick functionality to change the sizes & css properties */}
                                      </div>
                                    </div>
                                </div>
                                <div className="faq-wrap-2_answers">
                                    <p className='answer'>
                                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                                      Quod, quisquam ullam? Magni voluptate voluptatum error 
                                      ex expedita, omnis repudiandae ipsa pariatur ab, magnam, 
                                      quam provident eum iusto enim doloribus molestias earum 
                                      dolore ullam odit blanditiis dignissimos corrupti! Quod 
                                      alias obcaecati inventore mollitia voluptatum. Mollitia 
                                      necessitatibus atque rem provident accusantium ipsum.
                                    </p>
                                </div>
                             </div>
                             <div className="faq-wrap">                                
                             <div className="faq-wrap-2_questions">
                                    <div className="question">
                                    What is your refund or cancellation policy?
                                    </div>
                                    <div className="plus-wrap">
                                      <div className="plus-horizontal"></div>
                                      <div className="vertical">
                                        {/* Onclick functionality to change the sizes & css properties */}
                                      </div>
                                    </div>
                                </div>
                                <div className="faq-wrap-2_answers">
                                    <p className='answer'>
                                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                                      Quod, quisquam ullam? Magni voluptate voluptatum error 
                                      ex expedita, omnis repudiandae ipsa pariatur ab, magnam, 
                                      quam provident eum iusto enim doloribus molestias earum 
                                      dolore ullam odit blanditiis dignissimos corrupti! Quod 
                                      alias obcaecati inventore mollitia voluptatum. Mollitia 
                                      necessitatibus atque rem provident accusantium ipsum.
                                    </p>
                                 </div>
                              </div>
                             <div className="faq-wrap">                                
                             <div className="faq-wrap-2_questions">
                                    <div className="question">
                                    Do you offer customization for specific needs?
                                    </div>
                                    <div className="plus-wrap">
                                      <div className="plus-horizontal"></div>
                                      <div className="vertical">
                                        {/* Onclick functionality to change the sizes & css properties */}
                                      </div>
                                    </div>
                                </div>
                                <div className="faq-wrap-2_answers">
                                    <p className='answer'>
                                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                                      Quod, quisquam ullam? Magni voluptate voluptatum error 
                                      ex expedita, omnis repudiandae ipsa pariatur ab, magnam, 
                                      quam provident eum iusto enim doloribus molestias earum 
                                      dolore ullam odit blanditiis dignissimos corrupti! Quod 
                                      alias obcaecati inventore mollitia voluptatum. Mollitia 
                                      necessitatibus atque rem provident accusantium ipsum.
                                    </p>
                                </div>
                              </div>
                             <div className="faq-wrap">                                
                             <div className="faq-wrap-2_questions">
                                    <div className="question">
                                    What kind of technical support is available?
                                    </div>
                                    <div className="plus-wrap">
                                      <div className="plus-horizontal"></div>
                                      <div className="vertical">
                                        {/* Onclick functionality to change the sizes & css properties */}
                                      </div>
                                    </div>
                                </div>
                                <div className="faq-wrap-2_answers">
                                    <p className='answer'>
                                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                                      Quod, quisquam ullam? Magni voluptate voluptatum error 
                                      ex expedita, omnis repudiandae ipsa pariatur ab, magnam, 
                                      quam provident eum iusto enim doloribus molestias earum 
                                      dolore ullam odit blanditiis dignissimos corrupti! Quod 
                                      alias obcaecati inventore mollitia voluptatum. Mollitia 
                                      necessitatibus atque rem provident accusantium ipsum.
                                    </p>
                                </div>
                              </div>
                             <div className="faq-wrap">                                
                             <div className="faq-wrap-2_questions">
                                    <div className="question">
                                    How scalable are your services/products for growing businesses?
                                    </div>
                                    <div className="plus-wrap">
                                      <div className="plus-horizontal"></div>
                                      <div className="vertical">
                                        {/* Onclick functionality to change the sizes & css properties */}
                                      </div>
                                    </div>
                                </div>
                                <div className="faq-wrap-2_answers">
                                    <p className='answer'>
                                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                                      Quod, quisquam ullam? Magni voluptate voluptatum error 
                                      ex expedita, omnis repudiandae ipsa pariatur ab, magnam, 
                                      quam provident eum iusto enim doloribus molestias earum 
                                      dolore ullam odit blanditiis dignissimos corrupti! Quod 
                                      alias obcaecati inventore mollitia voluptatum. Mollitia 
                                      necessitatibus atque rem provident accusantium ipsum.
                                    </p>
                                </div>
                              </div>
                             <div className="faq-wrap">                                
                             <div className="faq-wrap-2_questions">
                                    <div className="question">
                                    Do you offer training or resources to help customers make the most of your services/products?
                                    </div>
                                    <div className="plus-wrap">
                                      <div className="plus-horizontal"></div>
                                      <div className="vertical">
                                        {/* Onclick functionality to change the sizes & css properties */}
                                      </div>
                                    </div>
                                </div>
                                <div className="faq-wrap-2_answers">
                                    <p className='answer'>
                                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                                      Quod, quisquam ullam? Magni voluptate voluptatum error 
                                      ex expedita, omnis repudiandae ipsa pariatur ab, magnam, 
                                      quam provident eum iusto enim doloribus molestias earum 
                                      dolore ullam odit blanditiis dignissimos corrupti! Quod 
                                      alias obcaecati inventore mollitia voluptatum. Mollitia 
                                      necessitatibus atque rem provident accusantium ipsum.
                                    </p>
                                </div>
                              </div>
                             <div className="faq-wrap">                                
                             <div className="faq-wrap-2_questions">
                                    <div className="question">
                                    Are there any additional fees or hidden costs I should know about?
                                    </div>
                                    <div className="plus-wrap">
                                      <div className="plus-horizontal"></div>
                                      <div className="vertical">
                                        {/* Onclick functionality to change the sizes & css properties */}
                                      </div>
                                    </div>
                                </div>
                                <div className="faq-wrap-2_answers">
                                    <p className='answer'>
                                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                                      Quod, quisquam ullam? Magni voluptate voluptatum error 
                                      ex expedita, omnis repudiandae ipsa pariatur ab, magnam, 
                                      quam provident eum iusto enim doloribus molestias earum 
                                      dolore ullam odit blanditiis dignissimos corrupti! Quod 
                                      alias obcaecati inventore mollitia voluptatum. Mollitia 
                                      necessitatibus atque rem provident accusantium ipsum.
                                    </p>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="space-medium"/>
                  <footer className="box-footer">
                      <div className="container_footer-right">
                        <div className="text-size-medium">
                        ©2023 PostScientistDevelopers. All rights reserved.
                        </div>
                        <div className="text-size-medium">
                        Based in the India, available worldwide.
                        </div>
                      </div>
                      <div className="container_footer-left">
                      <div className="text-size-medium">
                        Privacy Policy
                        </div>
                        <div className="text-size-medium">
                       Terms & conditions
                        </div>
                      </div>
                  </footer>
              </div>
           </div>
        </section>
        {/* <div className="contact-modal">
            <div className="modal_background">
                <div className="container_modal">
                    <div className="container_x">
                      <div className="plus-wrap-c">
                          <div className="plus-horizontal-c">

                          </div>
                          <div className="plus-vertical-c">

                          </div>
                      </div>
                      <div className="html">
                         <div className="inline-form">
                          <div className="form">
                            <div id="shadow-root">

                            </div>
                            <iframe src="/contact" frameborder="0"></iframe>
                          </div>
                         </div>
                      </div>
                    </div>
                </div>
            </div>
        </div> */}
    </div>
  )
}

export default landingpage
