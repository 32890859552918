import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Header from './components/Navbar';
// import Home from './components/Home';
// import Contact from './components/jobs';
import Landingpage from './components/landingpage';
import Navbar from './components/navbar';

import './styles/app.scss';
// import './styles/navbar.scss';
// import './styles/home.scss';
// import './styles/jobs.scss';
import './styles/landingpage.scss'
import './styles/navbar.scss'

function App() {
  return (
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Landingpage />}/>
        </Routes>
      </Router>
  );
}

export default App;
